<template lang="html">
<el-dialog :title="title" :visible.sync="dialogVisible">
  <el-table :data="info" border>
    <el-table-column property="action" label="动态行为" width="150"></el-table-column>
    <el-table-column property="createTime" label="动态时间" width="160"></el-table-column>
    <el-table-column property="content" label="动态备注"></el-table-column>
  </el-table>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false" type="primary">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
    export default {
    	data(){
    		return{
    			dialogVisible:false,
          accountTypeOptions:{
            1:'审核人员',
            2:'主账号',
            3:'专家团',
            4:'律师'
          }
    		}
    	},
      props:{
        title:{
          type:String,
          default:'用户动态'
        },
        info:{
          type:Array,
          default:function(){
            return []
          }
        }
      },
    	components:{},
    	methods:{}
    }
</script>

<style scoped lang="scss">

</style>