import instance from './axios.js'

// 通过认证申请
export const approveAuthApply = (para)=>{
  return instance.post('/account/approveAuthApply',para)
}
// 认证企业申请列表
export const companyApplyList = (para)=>{
  return instance.get('/account/companyApplyList',{params:para})
}
// 认证企业列表
export const companyList = (para)=>{
  return instance.get('/account/companyList',{params:para})
}
// 认证律师申请列表
export const lawyerApplyList = (para)=>{
  return instance.get('/account/lawyerApplyList',{params:para})
}
// 认证律师列表
export const lawyerList = (para)=>{
  return instance.get('/account/lawyerList',{params:para})
}
// 拒绝认证申请
export const rejectAuthApply = (para)=>{
  return instance.post('/account/rejectAuthApply',para)
}
// 认证个人申请列表
export const userApplyList = (para)=>{
  return instance.get('/account/userApplyList',{params:para})
}
// 认证个人列表
export const userList = (para)=>{
  return instance.get('/account/userList',{params:para})
}
// 用户认证审核列表
export const applyList = (para)=>{
  return instance.get('/account/applyList',{params:para})
}
// 用户认证审核详情
export const applyDetail = (para)=>{
  return instance.get('/account/applyDetail',{params:para})
}
// 招标管理列表
export const caseManageList = (para)=>{
  return instance.get('/case/caseManageList',{params:para})
}
// 招标/求助详情
export const caseDetail = (para)=>{
  return instance.get('/case/caseDetail',{params:para})
}
// 投标/报名详情
export const caseSubmitDetail = (para)=>{
  return instance.get('/case/caseSubmitDetail',{params:para})
}
// 投标/报名列表
export const caseSubmitList = (para)=>{
  return instance.get('/case/caseSubmitList',{params:para})
}
// 求助管理列表
export const helpManageList = (para)=>{
  return instance.get('/case/helpManageList',{params:para})
}
// 招标/求助审核
export const verify = (para)=>{
  return instance.post('/case/verify',para)
}
// 招标审核列表
export const waitVerifyCaseList = (para)=>{
  return instance.get('/case/waitVerifyCaseList',{params:para})
}
// 求助审核列表
export const waitVerifyHelpList = (para)=>{
  return instance.get('/case/waitVerifyHelpList',{params:para})
}
// 招标操作日志
export const caseOperateRecordList = (para)=>{
  return instance.get('/case/caseOperateRecordList',{params:para})
}
// 废标
export const invalid = (para)=>{
  return instance.post('/case/invalid',para)
}