<!--  -->
<template>
  <div class="table-search">
      <div class="flex-row">
          <div class="form">
            <el-input v-model="form.q" placeholder="请输入内容" style="width:260px;"></el-input>
            <el-select v-model="form.QType" placeholder="请选择" @change="changeHandle" class="t-s-select">
                <el-option
                v-for="item in conditions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" size="medium" @click="search">提交搜索</el-button>
        </div>
        <slot name="btns">
            
            
        </slot>
      </div>
      <div class="result">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <el-button type="text" @click="getTotal">全部数据</el-button>
                </el-breadcrumb-item>
                <el-breadcrumb-item>筛选条件：{{curLabel}} {{form.q}}</el-breadcrumb-item>
                <el-breadcrumb-item>共 {{total}} 条数据</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <el-button type="text" @click="freshHandle">结果刷新</el-button>
                </el-breadcrumb-item>
            </el-breadcrumb>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
        form:{
            q:'',
            QType:''
        },
        curLabel:'',
    };
  },
  props:{
      total:0,
      conditions:[]
  },
  components: {},
  computed: {},
  methods: {
      search(){
        let para = {[this.form.QType]:this.form.q};
        this.$emit('searchRes',para);
      },
      changeHandle(val){
          let res = this.conditions.find(item=>item.value === val);
          this.curLabel = res.label;
      },
      getTotal(){
        this.form.q = '';
        this.form.QType = '';
        this.$emit('searchRes',{});
      },
    freshHandle(){
      this.$emit('refresh')
    }
  }
}

</script>
<style lang='scss' scoped>
.flex-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form{
    display: flex;
    align-items: center;
    .el-input{
        margin-right: 15px;
    }
    .el-button{
        margin-left: 15px;
    }
}
.result{
    padding:15px 0;
    .el-breadcrumb{
        display: flex;
        align-items: center;
        font-size: 14px;
    }
}
</style>